<template>
    <div id="news-list">
        <el-form :inline="true" :model="form" class="demo-form-inline">
            <el-form-item label="ID">
                <el-input v-model="form.id" placeholder="ID"></el-input>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="loadList">查询</el-button>
            </el-form-item>
        </el-form>
        <el-divider></el-divider>
        <el-table :data="page.list" border>
            <el-table-column label="ID" prop="id" width="100">
            </el-table-column>
            <el-table-column label="标题" prop="title">
            </el-table-column>
            <el-table-column label="分类">
                <template v-slot="row">
                    <el-select v-model="row.row.newsType" placeholder="请选择" @change="edit(row.row)">
                        <el-option v-for="newsType in Object.keys(NEWS_TYPE)" :key="newsType"
                            :label="NEWS_TYPE[newsType]" :value="newsType">
                        </el-option>
                    </el-select>
                </template>
            </el-table-column>

            <el-table-column label="是否展示">
                <template v-slot="row">
                    <el-switch v-model="row.row.enable" active-color="#13ce66" inactive-color="#ff4949"
                        @change="edit(row.row)">
                    </el-switch>
                </template>
            </el-table-column>

            <el-table-column label="排序值(从小到大)" width="150">
                <template v-slot="row">
                    <el-input v-model="row.row.sortVal">
                        <el-button slot="append" icon="el-icon-check" @click="edit(row.row)" type="number"></el-button>
                    </el-input>
                </template>
            </el-table-column>

            <el-table-column label="操作">
                <template v-slot="row">
                    <el-button @click="goDetail(row.row.id)">详情</el-button>
                </template>
            </el-table-column>

        </el-table>
        <el-pagination style="text-align: center" :page-count="page.totalPage" :current-page="form.pageNo"
            layout="prev, pager, next" @current-change="changePage">
        </el-pagination>

        <el-dialog :visible.sync="dialogVisible">
            <img width="100%" :src="dialogImageUrl" alt="">
        </el-dialog>
    </div>
</template>
<script>
import { list, edit } from './news.js'
import { NEWS_TYPE } from '../../util/constants'

export default {
    name: "List",
    data() {
        return {
            NEWS_TYPE,
            editInfo: null,
            form: {
                id: null,
                pageNo: 1,
                pageSize: 20,
            },

            page: {
                list: null,
                totalPage: 1,
            },
        };
    },

    methods: {
        edit,
        loadList() {
            list(this.form).then((data) => {
                this.page = data;
            });
        },

        goDetail(id) {
            this.$router.push({
                path: '/news-edit',
                query: {
                    id: id
                }
            });
        },

    },

    created() {
        this.loadList();
    },

};
</script>

<style scoped></style>