import axios from 'axios'
import msg from '../../util/msg.js'



function create(news) {
    axios.post("/news/create", news).then((data) => { msg.success('成功') });
}

export function createOrEdit(news) {
    if (news.id) {
        edit(news)
    } else {
        create(news)
    }
}

export function edit(news) {
    axios.post("/news/edit", news).then((data) => { msg.success('成功') });
}

export function list(param) {
    return axios.get("/news/list", { params: param });
}


export function detail(id) {
    return axios.get("/news/detail/" + id, {});
}